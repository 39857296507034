@font-face {
    font-family: 'Onest Variable';
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/onest:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
    font-weight: 100 900;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sidebar {
    width: 240px;
    flex-shrink: 0;
    /* background-color: #f5f5f5; */
    padding: 16px;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif;
    margin: 0 8px 16px;
    border-radius: 24px;
}



/* TopBar.css */

/* Container for the entire top bar */
.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0 0 24px 24px;
    margin: 0 0 8px;
    position: relative;
}

/* Hamburger button for toggling the top bar */
.menu-toggle-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    display: none;
    /* Hidden by default, shown in smaller screens */
}

/* Top bar content (collapsible) */
.top-bar-content {
    display: flex;
    align-items: center;
    width: 100%;
}

/* Collapsed and expanded styles */
.top-bar-content.hide {
    display: none;
    /* Hide top bar content by default */
}

.top-bar-content.show {
    display: flex;
    /* Show top bar content when expanded */
}

.MuiPopover-paper {
    display: flex;
    align-items: center;
    border-radius: 16px !important;
    background-color: #ffffff !important;
    border: 1px solid #2e705c !important;
}

.MuiSelect-icon {
    color: #ffffff !important;
}

.business-selector {
    border: 1px solid #ffffff !important;
    background-color: #2e705c;
    color: #fff !important;

}


.business-selector-container {
    flex: 1;
}

.business-item.selected-button {
    background-color: #2e705c;
    color: #fff;
    border: 1px solid #ffffff;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    border-radius: 24px;
}

.search-container,
.MuiInputBase-root,
.MuiOutlinedInput-root {
    border-radius: 24px !important;
}

.search-container .MuiTextField-root {
    width: 100%;
    max-width: 400px;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    /* Limit the width of the search bar */
}

.profile-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.profile-section .MuiAvatar-root {
    cursor: pointer;
    margin-left: 16px;
    width: 32px;
    height: 32px;
}

/* 
.MuiOutlinedInput-input {
    color: #f5f5f5 !important;
} */


.profile-section {
    position: relative;
    /* To position the dropdown relative to the profile section */
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-avatar {
    display: inline-block;
    cursor: pointer;
}

.profile-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 120px;
    z-index: 20;
    /* Ensure it appears above other elements */
}

.logout-button {
    background-color: #d74f02;
    /* Match the existing "Cancel" button color */
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
}

.logout-button:hover {
    background-color: #bf4302;
}



/* TableView.css */

.table-view {
    width: 100%;
    border-collapse: collapse;
}

.edit-icon {
    color: #2e705c;
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #2e705c;
    margin: 0 4px;
}

.view-icon {
    color: #FF9537;
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #FF9537;
    margin: 0 4px;

}

.table-view th,
.table-view td {
    padding: 4px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 12px;
}

.table-view .expanded-row td {
    background-color: #f9f9f9;
    padding: 16px;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
}

.pagination-controls button {
    padding: 8px 16px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-controls button:hover {
    background-color: #e0e0e0;
}

.pagination-controls span {
    font-size: 12px;
    color: #1a1a1a;
}

/* GridView.css */

.grid-view {
    display: flex;
    width: 100%;
    border-collapse: collapse;

}

.grid-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
    flex: 2;
    margin: 8px 0 0 0;

}

.grid-card {
    padding: 16px;
    /* border: 1px solid #ddd; */
    border-radius: 16px;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    cursor: pointer;
    border: 0.5px solid #E4E6EC;
    box-shadow: 2px 2px 8px 0px #0000000A;
    /* border-radius: 16px; */
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.grid-card:hover {
    transform: translateY(-4px) scale(1.02);
    /* Add a slight translation and scaling effect on hover */
}

.grid-card h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1A1A1A;
}

/* DetailView.css */

/* Slide-in panel styles */
.slide-in-panel {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 35%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    /* Initially hidden */
    z-index: 100000;
    overflow-y: auto;
    border-radius: 24px 0 0 24px;
}

.slide-in-panel.visible {
    transform: translateX(0);
    /* Slide in when visible */
}

.slide-in-panel.hidden {
    transform: translateX(100%);
    /* Slide out when hidden */
}

.close-slide-in {
    position: absolute;
    top: 8px;
    right: 16px;
    background: #f5f5f5;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 8px 10px;
}

.slide-in-header {
    position: relative;
    padding: 16px 16px 0;
    border-bottom: 1px solid #e0e0e0;
}

.slide-in-header h2 {
    margin: 0 0 8px;
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
}

.slide-in-content {
    background: #ffffff;
    /* border-radius: 16px; */
    padding: 16px;
    max-width: 600px;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* Button container to use Flexbox */
.button-container {
    display: flex;
    justify-content: space-between;
    /* Puts left and right buttons at edges */
    align-items: center;
    /* Vertically aligns items */
    margin-bottom: 16px;
    /* Optional: adds space below buttons */
}

/* Container for buttons on the left */
.left-buttons {
    /* display: block; */
    padding: 8px 16px;
    margin: 8px;
    background-color: #f3f3f3;
    color: #1a1a1a;
    border: none;
    border-radius: 24px;
    /* Add 24px border radius */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    gap: 8px;
    /* Optional: space between the left buttons */
}

/* Styles for buttons on the left */
.left-button {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    padding: 8px 16px;
    border-radius: 24px;
    cursor: pointer;
}


.add-item-button {
    background: #D74F02;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    margin: 8px 16px;
}

.add-item-button:hover {
    background: #f57c00;
}

@media (max-width: 768px) {
    .slide-in-panel {
        width: 100%;
    }
}

/* Slide-in Panel Content Styling */
.slide-in-content {
    padding: 16px;
}

/* Form group styling */
.form-group {
    margin-bottom: 16px;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.form-group label {
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    color: #1a1a1a
}

.form-control {
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;

}

.react-datepicker {
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
    /* margin: 0 20px; */
}

.react-datepicker-popper {
    z-index: 100000 !important;
    left: 50px !important;
}

/* short form control */
.short-form-control {
    width: 50%;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;

}

/* Quantity Input styling */
.quantity-input {
    display: flex;
    align-items: center;
}

.quantity-input button {
    background-color: #f0f0f0;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 18px;
}

.quantity-input input {
    width: 100px;
    text-align: center;
    border: none;
    background: #ffffff;
    font-weight: bold;
}

/* Submit button styling */
.form-submit-button {
    width: 100%;
    padding: 12px;
    background-color: #2e705c;
    color: #ffffff;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-size: 16px;
}



/* Overlay for the detail view */
.detail-view-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
}

/* Card styling for the detail view */
.detail-view-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    max-width: 500px;
    width: 90%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    /* Start state for animation: hidden with scale down */
    opacity: 0;
    transform: scale(0.8);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* When the card is visible */
.detail-view-card.visible {
    opacity: 1;
    /* Fully visible */
    transform: scale(1);
    /* Full size */
}




/* Close button styling */
.close-button {
    position: absolute;
    top: 6px;
    right: 6px;
    background: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 50%;
    padding: 2px 4px;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.close-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* AppBar styles */
.app-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 8px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f8f9fa;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 2px 2px 8px 0px #0000000A;

}

.back-button,
.refresh-button {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
}

/* Product Information Section */
.product-info {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    font-size: 14px;
}

.product-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    font-weight: 600;
}

.info-item h4 {
    font-size: 12px;
    color: #6c757d;
    margin: 0 0 0px 0;
}

.info-item p {
    font-size: 12px;
    margin: 0 0 20px;
}

/* Details List styling */
.detail-list {
    margin-bottom: 16px;
    border-radius: 16px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #E4E6EC;
    box-shadow: 2px 2px 8px 0px #0000000A;

}

.detail-item {
    display: flex;
    justify-content: space-between;
    padding: 2px 0;
    font-size: 14px;
    color: #1a1a1a;
}

.product-category {
    font-size: 12px;
    color: #ffffff;
    /* Lighter color for category text */
    background: #D74F0290;

    padding: 4px 8px;
    border-radius: 24px;
    text-align: center;
    width: fit-content;
}

.divider {
    height: 1px;
    background: #F0F1F3;

    margin: 0px 0;
}

/* Edit Product Button */
.edit-product-button {
    background: #e0e0e0;
    border: none;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
}

.read-only {
    background: #f0f0f0;

}

/* Product History Section */
.product-history {
    background: #ffffff;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 1px solid #E4E6EC;
    box-shadow: 2px 2px 8px 0px #0000000A;
    color: #1a1a1a;

}

.history-item span {
    font-size: 18px;
    font-weight: bold;
    margin: 2px 0;
}

.history-item p {
    font-size: 12px;
    color: #6c757d;
}

/* History Item Container */
.history-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.history-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* History Icon Styling */
.history-icon-container {
    background-color: #f0f0f0;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.history-icon {
    color: #1a1a1a;
    font-size: 24px;
    /* Adjust size as needed */
}

/* History Text Styling */
.history-text {
    display: flex;
    flex-direction: column;
}

.history-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
}

.history-description {
    font-size: 14px;
    color: #6c757d;
    margin-top: 4px;
}

/* Actions Section */
.actions-section {
    display: flex;
    justify-content: space-between;
}

.stock-in-button {
    background: #28a745;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
}

.stock-out-button {
    background: #dc3545;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
}


.category-item {
    font-size: 14px;
    color: #1a1a1a;
    /* Lighter color for category text */
    background: #F3F3F3;
    padding: 4px 8px;
    border-radius: 24px;
    text-align: center;
}

.category-table-item {
    font-size: 12px;
    color: #1a1a1a;
    /* Lighter color for category text */
    background: #F3F3F3;
    padding: 4px;
    border-radius: 24px;
    text-align: center !important;
    text-align: center;
    margin: 8px 0;
}

.bold-text {
    font-weight: 500;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Slightly transparent dark background */
    z-index: 999;
    /* Place it under the detail view */
    display: none;
    /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
}

.overlay.open {
    display: block;
    opacity: 1;
}


/** selected view button color **/
.selected-list {
    background-color: #2e705c !important;
    color: #fff !important;
    border: 1px solid #ffffff;
}




/* Orange button */
.selected-report {
    background-color: #f57c00 !important;
    color: #fff !important;
    border: 1px solid #ffffff;
}


/* DashboardOverview.css */

.dashboard-overview {
    display: flex;
    gap: 10px;
    /* margin-bottom: 20px; */
    /* overflow-x: auto; */
    /* Allows horizontal scrolling if the cards exceed the container width */
    padding-bottom: 10px;
    /* Add padding to make space for horizontal scrolling */
}

.summary-card {
    min-width: 200px;
    flex: 1;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 16px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 2px 2px 8px 0px #0000000A;
    border: 0.5px solid #E4E6EC;
    position: relative;
}

.summary-card h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    /* line-height: 28px; */
    letter-spacing: -0.01em;
    text-align: left;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.card-icon {

    /* Adjust the size of the icon */

    /* Icon color matching branding */
    padding: 4px 6px;
    background: #fde6eb;
    /* opacity: 0.1; */
    border-radius: 8px;
}

.card-icon-green {
    font-size: 24px;
    /* Adjust the size of the icon */
    color: #2e705c;
    /* Icon color matching branding */
    /* padding: 4px; */

}

.card-icon-red {
    font-size: 24px;
    /* Adjust the size of the icon */
    color: #d32f2f;
    /* Icon color matching branding */
}

.card-icon-orange {
    font-size: 24px;
    /* Adjust the size of the icon */
    color: #f57c00;
    /* Icon color matching branding */
}

.summary-card:hover {
    transform: translateY(-4px);
}

h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #1A1A1A99;
}

h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
}

.category {
    font-size: 14px;
    color: #1a1a1a;
    /* Lighter color for category text */
    background: #F3F3F3;

    border-radius: 24px;
}

p {
    margin: 8px 0 0;
    font-size: 12px;
    color: #1a1a1a;
}

/* Item list */
.item-list {
    background-color: #ffffff;
    border-radius: 24px;
    padding: 12px;

}

/* Sidebar.css */

.sidebar {
    padding: 16px;
    background-color: #ffffff !important;
}

.category-button {
    display: block;
    width: 100%;
    padding: 8px 8px;
    margin-bottom: 8px;
    background-color: #f3f3f3;
    color: #1a1a1a;
    border: none;
    border-radius: 16px;
    /* Add 24px border radius */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.category-button:hover {
    background-color: #E4EEEF;
    color: #ffffff;
    /* Darker color on hover */
}

.category-button.selected {
    border: 1px solid #2e705c;
    background-color: #E4EEEF;
    color: #2e705c;
    /* Darker color for selected category */
}

.select-category-button {
    /* display: block; */
    padding: 8px 16px;
    margin: 8px;
    background-color: #f3f3f3;
    color: #1a1a1a;
    border: none;
    border-radius: 24px;
    /* Add 24px border radius */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.select-category-button:hover {
    background-color: #488371;
    color: #ffffff;
    /* Darker color on hover */
    /** selected view button color **/
}


/* Suppliers Button */
.suppliers-button {
    display: block;
    width: 100%;
    padding: 8px 8px;
    margin-bottom: 8px;
    background-color: #bf4302;
    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 16px;
    /* Add 24px border radius */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.suppliers-button:hover {
    background-color: #E4EEEF;
    color: #2e705c;
    /* Darker color on hover */
}

/* Suppliers Button */
.suppliers-button.selected {
    background-color: #2e705c;
    color: #ffffff;
    border: 1px solid #ffffff;
}

/* Suppliers Button */
.suppliers-button.selected-button {
    background-color: #2e705c;
    color: #ffffff;
    border: 1px solid #ffffff;
}



/* BusinessSelector.css */

.open-business-selector {
    padding: 8px 20px;
    background-color: #2e705c;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.open-business-selector:hover {
    background-color: #488371;
}

.business-selector-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.business-list {
    padding: 16px;
    max-height: 400px;
    overflow-y: auto;
}

.business-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.business-item.selected {
    border-color: #2e705c;
}

.arrow-icon {
    margin-left: 8px;
    padding-top: 16px;
    font-size: 12px;
    /* Adjust size as needed */
    color: #ffffff;
    /* Ensure it matches the button text color */
}

.business-info {
    display: flex;
    align-items: center;
}

.business-logo {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    margin-right: 16px;
}

.business-name {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
}

.product-count {
    margin: 4px 0 0;
    font-size: 14px;
    color: #757575;
}

.edit-business-button {
    padding: 8px 16px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 24px;
    cursor: pointer;
}

.edit-business-button:hover {
    background-color: #e0e0e0;
}

.add-business-button-container {
    padding: 16px;
    display: flex;
    justify-content: center;
}

.add-business-button {
    padding: 12px 24px;
    background-color: #2e705c;
    color: #fff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
}

.add-business-button:hover {
    background-color: #488371;
}

.stock-in-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    /* Ensures it covers the whole screen */
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Ensures it is behind the slide-up panel */
}

.stock-in-overlay.hidden {
    display: none;
}


.stock-in-overlay.visible {
    height: 100%;
    /* Or a fixed height, e.g., 50% of the screen */

}


.slide-up-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    width: 90%;
    /* Full width for mobile view */
    max-width: 600px;
    /* Set max-width to control how wide it can be */
    margin: 0 auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;

    /* Transition and initial transform to animate */
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

/* When panel becomes visible */
.slide-up-panel.visible {
    transform: translateY(0);
}


/* When panel is hidden */
.slide-up-panel.hidden {
    transform: translateY(100%);
    /* Move the panel below the screen */
}


.slide-up-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.slide-up-header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #4CAF50;
}

.slide-up-content {
    padding: 16px;
}

.close-slide-up {
    background: #f5f5f5;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 8px 10px;
}

.short-form-group {
    margin-bottom: 20px;
    font-size: 14px;
    width: 50%;
}

.short-form-group label {
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    color: #1a1a1a;
    width: 100%;
}

.short-form-group-inline {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
}

.short-form-group-inline .short-form-group {
    flex: 1;
}

.short-form-control-inline {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}

.short-form-submit-button {
    width: 30%;
    padding: 8px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 24px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
    margin-right: 10px;
}

.short-form-submit-button:hover {
    background-color: #45a049;
}

.short-form-cancel-button {
    width: 30%;
    padding: 8px;
    background-color: #D74F02;

    color: white;
    border: none;
    margin: 0;
    border-radius: 24px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;


}

.short-input-group {
    display: flex;
    align-items: center;
    gap: 5px;
}

.quantity-decrement,
.quantity-increment {
    background-color: #ddd;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.quantity-decrement:hover,
.quantity-increment:hover {
    background-color: #bbb;
}

.short-form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}

.download-template-button {
    padding: 8px 16px;
    background-color: #ffffff !important;
    color: #1a1a1a !important;
    /* border: 1px solid #1a1a1a99 !important; */
    border-radius: 24px !important;
    cursor: pointer;
    font-size: 12px !important;
    margin: 10px !important;
}

.download-icon {
    color: #FF9537 !important;
}

.upload-icon {
    color: #2e705c !important;
}

.upload-inventory-button {
    padding: 8px 16px;
    background-color: #ffffff !important;
    color: #1a1a1a !important;
    /* border: 1px solid #1a1a1a99 !important; */
    border-radius: 24px !important;
    cursor: pointer;
    font-size: 12px !important;
    margin: 10px !important;
}

/* upload inventory dialog */
.dialog-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px !important;
    border-bottom: 1px solid #f0f0f0;
    color: #28a745;

}

.dialog-content {
    padding: 16px !important;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.dialog-content input {
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.cancel-button {
    padding: 8px 16px;
    background-color: #f5f5f5;
    color: #1a1a1a;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
}

.upload-button {
    padding: 8px 16px;
    background-color: #28a745 !important;
    color: #fff !important;
    border: none;
    border-radius: 24px !important;
    cursor: pointer;
    font-size: 14px;
}

/* media queries */
@media (max-width: 768px) {
    .slide-up-panel {
        width: 100%;
    }

    .short-form-group {
        width: 100%;
    }

    .short-form-submit-button {
        width: 100%;
    }

    .short-form-cancel-button {
        width: 100%;
    }

    .short-form-control {
        width: 100%;
    }

    .short-form-control-inline {
        width: 100%;
    }

    .top-bar {
        flex-direction: column;
        align-items: stretch;
        padding: 12px;
    }

    .menu-toggle-button {
        display: block;
        /* Show menu toggle button */
    }

    .top-bar-content {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 10px;
    }

    .top-bar-content.hide {
        display: none;
        /* Hide the top bar content by default on small screens */
    }

    .search-container {
        margin-top: 10px;
        width: 100%;
    }

    .top-bar-content.show {
        display: flex;
        /* Show the top bar content when expanded */
    }

    .inventory-buttons {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .profile-section {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .logo {
        width: 50% !important;
        margin-bottom: 12px;
        align-self: center;
    }

    .search-container {
        margin-top: 10px;
        order: 3;
        /* Place search container below business selector */
    }

    .inventory-buttons {
        /* display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        order: 4;
        /* Place inventory buttons below search container */
        margin-top: 12px;
    }

    .profile-section {
        margin-top: 12px;
        order: 5;
        /* Place profile section at the bottom */
    }

    .business-selector {
        margin-bottom: 10px;
        width: 100%;
    }

    .dashboard-overview {
        flex-direction: column;
        gap: 16px;
        padding: 10px;
    }

    .summary-card {
        min-width: auto;
        /* Let the card take full width */
        width: 100%;
        /* Make sure each card takes the entire container width */
    }


}

/* Phones (576px and below) */
@media screen and (max-width: 576px) {
    .top-bar-content {
        padding: 0;
    }

    .inventory-buttons {
        flex-direction: column;
        align-items: stretch;
        margin-top: 8px;
    }

    .profile-section {
        align-items: center;
        margin-top: 8px;
    }

    .search-container {
        margin-top: 8px;
        width: 100%;
    }

    .logo {
        width: 50% !important;
        align-self: center;
    }

    .search-container {
        flex-direction: column;
        align-items: stretch;
        margin-left: 0;
        margin-top: 8px;
    }

    .MuiTextField-root {
        max-width: 100%;
        margin: 8px 0;
    }

    .inventory-buttons {
        flex-direction: column;
        width: 100%;
        margin: 8px 0;
    }

    .profile-section {
        flex-direction: column;
        gap: 5px;
        align-items: center;
        width: 100%;
        margin-top: 8px;
    }

    .profile-avatar {
        margin-top: 8px;
    }

    .dashboard-overview {
        padding: 8px;
    }

    .summary-card {
        padding: 12px;
        /* Reduce padding to save space */
        font-size: 14px;
        /* Adjust text size for smaller screens */
        box-shadow: 1px 1px 6px 0px #0000000A;
        /* Reduce shadow for smaller displays */
        border-radius: 16px;
        /* Reduce border radius for smaller screens */
    }
}