.subscription-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full-screen height */
    background-color: #f5f5f5;
}

.subscription-card {
    max-width: 400px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 24px !important;
}

.subscription-button {
    background-color: #2E705C;
    color: #FFFFFF;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 24px !important;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}