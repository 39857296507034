/* Dashboard.css */

@font-face {
    font-family: 'Onest Variable';
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/onest:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
    font-weight: 100 900;
    font-display: swap;

}

.MuiTypography-root {
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiMenuItem-root,
.MuiSelect-root {
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}


body {
    margin: 0;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.top-bar {
    flex: 0 1 auto;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif;
}

.dashboard-main {
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif;

    display: flex;
    flex: 1;
    overflow: hidden;
}

.sidebar {
    width: 140px;
    flex-shrink: 0;
    background-color: #f5f5f5;
    padding: 16px;
}

.dashboard-content {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
}

.search-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #2e705c;
    background-color: #f8f9fa;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 2px 2px 8px 0px #0000000A;
}

.search-header h4 {
    margin: 0;
}

.search-results {
    padding: 16px;
    position: absolute;
    background-color: #ffffff;
    top: 50px;
    margin: 0 auto;
    left: 30%;
    border-radius: 24px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 100;
}

.search-results ul {
    list-style-type: none;
    padding: 0;
}

.search-results li {
    cursor: pointer;
    font-size: 14px;
    padding: 4px;
}

.search-results li:hover {
    background-color: #f8f9fa;
}

.green-text {
    color: #2e705c !important;
    font-size: 14px !important;
}

.logo {
    width: 10%;
    margin: 0 40px 0 0;
}

.reporting-panel {
    /* width: 250px; */
    flex-shrink: 0;
    padding: 16px;
    background-color: #ffffff;
    overflow-y: auto;
    border-radius: 24px;
}

.footer {
    flex: 0 1 60px;
    background-color: #2e705c;
    color: white;
    text-align: center;
    padding: 12px;
    font-weight: bold;
}

/* Media Queries */

/* Small devices (tablets, 768px and below) */
@media screen and (max-width: 768px) {
    .dashboard-main {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        padding: 8px;
        order: 1;
        /* Place sidebar before main content in the order */
    }

    .dashboard-content {
        padding: 8px;
        order: 2;
    }

    .reporting-panel {
        order: 3;
        margin-top: 16px;
    }
}

/* Extra small devices (phones, 576px and below) */
@media screen and (max-width: 576px) {
    .dashboard-main {
        padding: 8px;
    }

    .sidebar {
        display: none;
        /* Hide sidebar for small devices */
    }

    .top-bar {
        padding: 8px;
        text-align: center;
        /* Center align top-bar elements for small screens */
    }

    .dashboard-content {
        padding: 8px;
    }

    .search-results {
        left: 10%;
        width: 80%;
    }

    .reporting-panel {
        width: 100%;
        padding: 8px;
    }
}