/* SupplierProfileModal.css */

/* General Modal Styling */
.supplier-details-card {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 0px #0000000A;
    padding: 8px;
    margin: 8px 0;
}

.order-history-card {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 0px #0000000A;
    padding: 8px;
    margin: 8px 0;
}

.order-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Supplier Detail Row Styling */
.supplier-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0;
}

hr {
    margin: 0;
}

/* Title Styling for Supplier Details */
.supplier-detail-title {
    font-size: 14px !important;
    color: #1A1A1A99;
    font-weight: 500;
    /* line-height: 1em !important; */
}

.edit-button {
    background-color: #2E705C;
    color: #FFFFFF;
    border-radius: 24px;
    padding: 8px 24px;
    text-transform: none;
    /* Prevents text from being all uppercase */
    font-weight: bold;
}

/* Value Styling for Supplier Details */
.supplier-detail-value {
    font-size: 14px !important;
    line-height: 1em !important;
    color: #1A1A1A !important;
    font-weight: 600 !important;
    text-align: right;
    margin-left: 8px;
    /* Adds space between title and value */
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.supplier-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-filter-select {
    margin-right: 16px;
    padding: 0 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1A1A1A99 !important;
    border-radius: 24px !important;
    border: 1px solid #f57c11 !important;


}

.date-filter-select .MuiSelectMuiSelect-select .MuiInputBase-input .MuiOutlinedInput-input {
    padding: 0px 0px !important;
}

/* Select MenuItem */
/* SupplierProfileModal.css */
.custom-menu-item {
    font-weight: bold !important;
    color: #2E705C !important;
    background-color: #F0F1F3 !important;
    padding: 0 !important;
    font-size: 12px !important;
}

.custom-menu-item:hover {
    background-color: #DCE4E7 !important;
}

/* Dialog Title Styling */
.MuiDialog-paper {
    border-radius: 24PX !important;

}

.MuiDialogTitle-root {
    background-color: #38B635;
    /* Matches the green color in your Flutter app */
    color: #FFFFFF !important;
    padding: 12px 14px !important;
    /* border-top-left-radius: 16px;
    border-top-right-radius: 16px; */
    /* border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; */
    font-weight: bold;
}

/* Dialog Content Styling */
.MuiDialogContent-root {
    background-color: #F0F1F3;
    /* Light background color for contrast */
    padding: 0 16px !important;
}

/* Dialog Actions Styling */
.MuiDialogActions-root {
    background-color: #FFFFFF;
    padding: 16px;
    justify-content: space-between;
}

/* Button Styling for Dialog Actions */
.MuiButton-containedPrimary {
    background-color: transparent !important;
    color: #1A1A1A99 !important;
    border: #2E705C 1px solid !important;
    border-radius: 24px !important;
    padding: 8px 24px;
    text-transform: none !important;
    /* Prevents text from being all uppercase */
    font-weight: bold;
}

.MuiButton-containedSecondary {
    background-color: transparent !important;
    color: #1A1A1A99 !important;
    border: #f57c11 1px solid !important;
    border-radius: 24px !important;
    padding: 8px 24px;
    text-transform: none !important;
    /* Prevents text from being all uppercase */
    font-weight: bold;
    /* border shadow */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Close Button Styling */
button[style*="float: right"] {
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-size: 14px;

}

/* Divider Styling */
.MuiDivider-root {
    color: #E8F0F9 !important;
    /* Light gray color for divider lines */
    height: 1px;
    margin: 8px 0 !important;
}

/* Typography Styling */
.MuiTypography-h6 {
    color: #1A1A1A;
    font-weight: bold;
    margin-bottom: 8px;
}

/* Export and Edit Buttons */
.supplier-details-card button {
    margin-right: 8px;
}

/* Button Hover Effect */
.MuiButton-containedPrimary:hover,
.MuiButton-containedSecondary:hover {
    background-color: #265c4c !important;
    color: #FFFFFF !important;
    /* Slightly darker shade for hover effect */
}


/* MuiTable Styling */
.MuiTable-root {
    border-collapse: collapse !important;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
    width: 100%;
}

/* MuiTableHead Styling */
.MuiTableHead-root {
    background-color: #E8F0F9;
    /* Light blue color for table header */
}

/* MuiTableCell Styling */
.MuiTableCell-root {
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
    border: 1px solid #E8F0F9;
    /* Light gray color for table borders */
    padding: 8px !important;
    text-align: left;

}

/* MuiTableRow Styling */
.MuiTableRow-root {
    border-bottom: 1px solid #E8F0F9;
    /* Light gray color for table borders */
}


/* Responsive Styles */
@media (max-width: 768px) {
    .supplier-detail-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .supplier-detail-value {
        text-align: left;
        margin-left: 0;
    }

    .MuiDialogContent-root {
        padding: 16px;
    }

    .MuiDialogTitle-root,
    .MuiDialogActions-root {
        padding: 12px;
    }

    .MuiButton-containedPrimary,
    .MuiButton-containedSecondary {
        padding: 8px 16px;
    }

    .MuiTypography-h6 {
        font-size: 16px;
    }

    .MuiTableCell-root {
        padding: 8px;
    }

    .MuiTableHead-root {
        padding: 8px;
    }

    .MuiTableRow-root {
        padding: 8px;
    }

    .MuiTable-root {
        font-size: 14px;
    }

    .MuiTableHead-root {
        font-size: 14px;
    }

    .MuiTableCell-root {
        font-size: 14px;
    }

    .MuiTableRow-root {
        font-size: 14px;
    }

    .MuiTypography-h6 {
        font-size: 16px;
    }
}