.suppliers-page {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 0px #0000000A;
    padding: 8px;
    margin: 8px 0;
}

.suppliers-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* GridView.css */

.grid-view {
    display: flex;
    width: 100%;
    border-collapse: collapse;
}

.grid-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
    flex: 2;
    margin: 8px 0 0 0;
}

.grid-card {
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    border: 0.5px solid #E4E6EC;
    box-shadow: 2px 2px 8px 0px #0000000A;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.grid-card:hover {
    transform: translateY(-4px) scale(1.02);
}

.grid-card h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1A1A1A;
}

/* Container for buttons on the left */
.view-toggle-buttons {
    /* display: block; */
    padding: 8px 16px;
    margin: 8px 0;
    background-color: #E8F0F9;
    color: #1a1a1a;
    border: none;
    border-radius: 24px;
    /* Add 24px border radius */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    gap: 8px;
    /* Optional: space between the left buttons */
}

/* Styles for buttons on the left */
.view-toggle {
    background-color: #ffffff;
    color: #333;
    border: none;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 24px;
    cursor: pointer;
}

/* .active {
    background-color: #F57C11 !important;
    color: #fff !important;
    border: 1px solid #ffffff;
    font-weight: bold;
} */

.item-list {
    background-color: #ffffff;
    border-radius: 24px;
    padding: 12px;
    /* border-color: #ffffff; */
}

.grid-value {
    font-weight: 600;
    color: #1A1A1A;
    text-align: right;
    margin-left: auto;
    line-height: 1em;
    /* Add space between the label and value */

}

.grid-card p {
    margin: 0;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1A1A1A99;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid-card h3 {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1A1A1A;
    border-bottom: 1px solid #E4E6EC;
}

/* TableView.css */

.suppliers-table {
    width: 100%;
    /* border-collapse: collapse; */
    margin: 16px 0;
}

.edit-icon {
    color: #2e705c;
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #2e705c;
    margin: 0 4px;
}

.view-icon {
    color: #FF9537;
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #FF9537;
    margin: 0 4px;

}

.suppliers-table thead {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    font-weight: 600;
}

.suppliers-table th,
.suppliers-table td {
    padding: 4px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 12px;
}

.suppliers-table .expanded-row td {
    background-color: #f9f9f9;
    padding: 16px;
}