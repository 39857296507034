/* ProductHistory Overlay */
.product-history-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Ensure it is above other elements */
}

.product-history-overlay.visible {
    transform: translateY(0);
    /* Slide in when visible */
}

.product-history-overlay.hidden {
    transform: translateY(100%);
    /* Slide out when hidden */
}

.red-text {
    color: #dc3545 !important;
    font-size: 14px !important;
}

.orange-text {
    color: #D74F02 !important;
    font-size: 14px !important;
}

.product-history-container {
    position: relative;
    background: #f5f5f5;
    /* border-radius: 16px; */
    padding: 16px;
    max-width: 600px;
    /* width: 90%; */
    max-height: 90%;

}

.product-history-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 16px;
    max-width: 600px;
    /* width: 90%; */
    max-height: 90%;
    overflow-y: auto;
    /* Allow scrolling if content exceeds height */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}


.grouped-history {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* background-color: #e4eeef; */
}

.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px 8px;
    background: #ffffff;
    padding: 0 16px;
    border-radius: 16px;
    border: 2px solid #2e705c;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.group-header h2 {
    /* padding: 16px; */
    font-size: 16px;
    font-weight: 600;
    color: #2e705c;
    margin: 0;
    /* border-radius: 16px; */
}

.group-header button {
    background: #2e705c;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.group-header span {
    font-size: 14px;
    color: #666666;
    font-weight: 600;
    font-size: 24px;
}

/* Group-header for alert */
.group-header-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px 8px;
    background: #ffffff;
    padding: 0 16px;
    border-radius: 16px;
    border: 2px solid #ffc107;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.group-header-alert h2 {
    /* padding: 16px; */
    font-size: 16px;
    font-weight: 600;
    color: #2e705c;
    margin: 0;
    /* border-radius: 16px; */
}

.group-header-alert button {
    background: #2e705c;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.group-header-alert span {
    font-size: 14px;
    color: #666666;
    font-weight: 600;
    font-size: 24px;
}

/* Product History Section */
.product-history {
    background: #ffffff;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 1px solid #E4E6EC;
    box-shadow: 2px 2px 8px 0px #0000000A;
    color: #1a1a1a;

}

/* Product History Section */
.product-history-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 16px;
    max-width: 600px;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* History Item Container */
.product-history-item-container {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    /* padding: 16px; */
}

/* History Item Styling */
.product-history-item {
    display: flex;
    flex-direction: column;
    /* padding: 16px; */
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    position: relative;
}

.product-history-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Product Header */
.product-history-header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 8px 0;
}

.product-history-info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-history-type {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
}

.product-history-type.stock-in {
    color: #488371 !important;
    /* Green for Stock In */
}

.product-history-type.stock-out {
    color: #dc3545;
    /* Red for Stock Out */
}

.product-history-type.alert {
    color: #ffc107;
    /* Yellow for Alerts */
}

.product-history-date {
    text-align: right;
    font-size: 12px;
    color: #6c757d;
    font-weight: 500;
}

.product-history-date span {
    display: block;
}

/* Divider between product details and updated quantity */
.product-history-divider {
    height: 1px;
    width: 100%;
    background-color: #E4E6EC;
    margin: 8px 0 0;
}

/* History Details Section */
.product-history-details {
    font-size: 14px;
    color: #1A1A1A99 !important;
    font-weight: 500;
    padding: 0 8px;
}

.padded-text {
    padding: 0 0 8px;
}

.unpadded-text {
    padding: 0;
    margin: 0;
    color: #1A1A1A99;

}

.preset-dates {
    font-size: 12px;
    color: #6c757d;
    font-weight: 500;
    margin: 0;
}

.preset-dates button {
    /* background: #2e705c; */
    color: #1a1a1a;
    border: 1px solid #2e705c;
    border-radius: 24px;
    padding: 2px 8px;
    cursor: pointer;
    margin: 2px 2px;
    font-size: 12px;

}

/* selected date button */
.preset-dates .selected {
    background: #2e705c;
    color: white;
}

/* selected buitton hover */
.preset-dates button:hover {
    background: #488371;
    color: white;
}

.calendar-icon {
    font-size: 16px;
    color: #2e705c;
    margin: 20px 10px;
    position: relative;
    top: 10px;
}

.updated-quantity {
    font-size: 12px;
    color: #1a1a1a;
    font-weight: 500;
    margin: 0 !important;
    padding: 4px 8px;
}

.updated-quantity p {
    margin: 0 !important;
}

/* Icon Styling */
.product-history-icon-container {

    background-color: #f0f0f0;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2e705c;
    color: #1a1a1a;
    font-size: 14px;
}

.product-history-item.stock-in {
    border-left: 2px solid #488371;
    /* Green border for Stock In */
}

.product-history-item.stock-out {
    border-left: 2px solid #dc3545;
    /* Red border for Stock Out */
}

.product-history-item.alert {
    border-left: 2px solid #ffc107;
    /* Yellow border for Alerts */
}

.product-history-icon.stock-in {
    color: #28a745;
    /* Green for Stock In */
}

.product-history-icon.stock-out {
    color: #dc3545 !important;
    /* Red for Stock Out */

}

.product-history-icon.alert {
    color: #ffc107;
    /* Yellow for Alerts */
}