/* ReportingPanel.css */

.reporting-panel {
    margin-top: 20px;
}

.tabs {
    display: flex;
    border-bottom: 0;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 24px;
    margin: 10px 0;
}

/* Report.css */

.report-button {
    padding: 8px 16px;
    background-color: #D74F02;
    font-weight: 500;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
    /* margin: 0 0 20px 0; */
}

.tab-button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 24px;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 10px;
}

.tab-button.active {
    background-color: #4CAF50;
    /* Active tab background */
    color: white;
    /* Active tab text color */
    border-bottom: 2px solid #4CAF50;
    /* Highlights the active tab */
}

.tab-button:hover {
    background-color: #4CAF50;
    color: white;
}

.tab-content {
    margin-top: 20px;
}

.report-content {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 24px;
    background-color: #ffffff;
}

.report-content h4 {
    margin: 10px 0 0 0;
    padding: 0;
}

.right-align {
    text-align: right !important;
}


/*export button on the right*/
.export-button-container {
    display: flex;
    justify-content: flex-end;
}

.export-button {
    padding: 8px 16px;
    background-color: #2E705C;
    font-weight: 500;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 0 20px 10px;
}

.reporting-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*time-filter is a dropdwon */

.time-filter {
    padding: 8px 12px;
    border: 1px solid #2E705C;
    border-radius: 24px;
    cursor: pointer;
    /* background-color: #f0f0f0; */
    margin-right: 10px;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.report-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 12px;
}

.report-table th,
.report-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.report-table th {
    background-color: #f0f0f0;
}

.report-table tbody tr:hover {
    background-color: #f9f9f9;
}