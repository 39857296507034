.chart-text {
    font-size: 12px !important;
    color: #1a1a1a;
    font-weight: 500;
    margin: 0;
}

.recharts-cartesian-axis-ticks {
    font-size: 12px !important;
    color: #1a1a1a;
    font-weight: 500;
    margin: 0;
}

.recharts-default-tooltip {
    font-size: 12px !important;
    color: #ffffff !important;
    font-weight: 500;
    margin: 0;
    /* background-color: #1a1a1a !important; */
    border-radius: 8px !important;
}



.recharts-default-legends {
    font-size: 12px !important;
    color: #1a1a1a;
    font-weight: 500;
    margin: 0;
}