/* Login.css */

@font-face {
    font-family: 'Onest Variable';
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/onest:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
    font-weight: 100 900;
    font-display: swap;
}

/* Login Page Styling */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Onest Variable', -apple-system, BlinkMacSystemFont, sans-serif !important;
    /* background-color: #e4eeef; */
}

.login-container {
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.login-logo {
    width: 40%;
    margin: 0 0px 0 0;
}

.login-header-text {
    font-size: 24px !important;
    font-weight: 500;
    color: #1A1A1A;
    margin: 10px 0px 10px !important;
    line-height: 1em !important;
}

/* Custom Styling for TextField Label */
.text-field-label {
    font-weight: 500;
    font-size: 12px;
    color: #1A1A1A;
    margin-bottom: 4px;
}

/* Custom Button Styling */
.custom-button {
    background-color: #2E705C !important;
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 40px !important;
    border-radius: 40px !important;
    border: 2px solid #488371 !important;
    transition: background-color 0.3s, transform 0.1s;
}

.custom-button:hover {
    background-color: #488371 !important;
}

.custom-button:active {
    transform: scale(0.98);
}

/* Optional Styling for Read-only and Disabled Fields */
.text-field-input[readonly] {
    background-color: rgba(26, 26, 26, 0.2);
}